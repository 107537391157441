import React, {useState} from 'react';
import { withContainer } from '../../Container/container.js'
import './klänge.css';
import Klang from "./Klang";
import {Carousel} from 'react-bootstrap'

// 1745 width breaking point 

function Klaenge() {
    const [changer, setChanger] = useState(null);
    const [index, setIndex] = useState(0);
  
    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };


// #region 

    // #0
    
    var titel0 = "ATMOSPHERE"
    var interpret0 = "musik: joy division | text: ian curtis"

    var text0 = (
      <div>
        <div>erschienen im jahr des freitodes von</div>
        <div>joy division-sänger ian curtis, ist dieses lied</div>
        <div>über die tiefe depression, die aus seiner</div>
        <div>unrettbar scheiternden ehe hervorging, eine</div>
        <div>meditation über liebe, verlust und trauer.</div>
    </div>
    )
    var link0= "https://youtu.be/3dslvsgLJkU"

    const KLANG0 = <Klang titel={titel0} interpret={interpret0} text={text0} link={link0} />

    // #1
    var titel1 = "BLACKBIRD";
    var interpret1 = "paul mccartney";
    var text1 = (
      <div>
        <div>dieses 1968 durch den morgendlichen ruf</div>
        <div>einer amsel inspirierte lied wurde als leiser</div>
        <div>protest gegen die rassendiskriminierungen in </div>
        <div>den usa gedeutet und ist eine prägnante</div>
        <div>metapher für das erwachen.</div>
      </div>
)
    var link1 = "https://youtu.be/Ivx9Hx6XXes";
    const KLANG1 = <Klang titel={titel1} interpret={interpret1} text={text1} link={link1}/>


    // #2 
    var titel2 = "GRÜN ARME (GREENSLEEVES)";
    var interpret2 = "traditional | deutscher text: friedrich karl barth";
    
    
    // var text1 = "das bekannte traditional aus dem elisabethanischen england wird in der textfassung von friedrich karl barth zur zeitlosen meditation über das werden und vergehen."
    
    var text2 = (
                <div>
                  <div>das bekannte traditional aus dem</div>
                  <div>elisabethanischen england wird in</div>
                  <div>der textfassung von friedrich karl barth</div>
                  <div>zur zeitlosen meditation über das</div>
                  <div>werden und vergehen.</div>
                </div>
      )
    
    
    var link2 = "https://youtu.be/K2Qy55LfwLQ"
    const KLANG2 = <Klang titel={titel2} interpret={interpret2} text={text2} link={link2} />
    
    var titel3 = "IMAGINE";
    var interpret3 = "john lennon";
    var text3 = (
      <div>
        <div>die 1971 von john lennon komponierte</div>
        <div>hymne der friedensbewegung beschreibt</div>
        <div>die utopie einer gesellschaft</div>
        <div>frei von religion, nationalismus</div>
        <div>und persönlichem besitz.</div>
      </div>
)
    
    var link3 ="https://youtu.be/zBx_7bnhoPM"
    const KLANG3 = <Klang titel={titel3} interpret={interpret3} text={text3} link={link3} />
    

    var titel4 = "MORNING HAS BROKEN";
    var interpret4 = "eleanor farjeon";
    var text4 = (
      <div>
        <div>dieses 1931 von eleanor farjeon für die</div>
        <div>{"englische liedersammlung ›songs of praise‹"}</div>
        <div>geschriebene lied über die unendliche</div>
        <div>schönheit der natur wurde 1971 in der version</div>
        <div>von cat stevens weltberühmt.</div>
      </div>
)
    
    var link4 ="https://youtu.be/n-jxcyuF44o"
    const KLANG4 = <Klang titel={titel4} interpret={interpret4} text={text4} link={link4} />
    

    var titel5 = "ONE";
    var interpret5 = "musik: u2 | text: bono";
 //   var text4 = "dieses vor allem in der reduzierten version von johnny cash tief bewegende lied kann als eine art innerer monolog über den universellen charakter von liebesbeziehungen gedeutet werden."
       
    var text5 = (
      <div>
        <div>dieses vor allem in der reduzierten </div>
        <div>version von johnny cash tief bewegende </div>
        <div>lied kann als eine art innerer monolog</div>
        <div>über den universellen charakter von</div>
        <div>liebesbeziehungen gedeutet werden.</div>
      </div>
)
   
    var link5 = "https://www.youtube.com/watch?v=h4K58jz5X-I";
    const KLANG5 = <Klang titel={titel5} interpret={interpret5} text={text5} link={link5}/>
    
    var titel6 = "RIVER MAN";
    var interpret6 = "nick drake";

    var text6 = (
      <div>
        <div>dieses im ungewöhnlichen 5/4-takt</div>
        <div>geschriebene lied des viel zu früh </div>
        <div>verstorbenen englischen folk-poeten nick</div>
        <div>drake behandelt die essenzielle frage</div>
        <div> nach dem sinn des lebens.</div>
      </div>
)
    
    var link6 = "https://youtu.be/neOXiI06JAo"
    const KLANG6 = <Klang titel={titel6} interpret={interpret6} text={text6} link={link6}/>
    
    var titel7 = "THE SOUND OF SILENCE";
    var interpret7 = "paul simon";

    var text7 = (
      <div>
        <div>1964 von paul simon allein in seinem</div>
        <div>abgedunkelten badezimmer geschrieben,</div>
        <div>wurde dieses lied über vereinsamung</div>
        <div>und kommunikationsunfähigkeit erst 1967</div>
        <div>{"durch ›die reifeprüfung‹ zum welthit."}</div>
      </div>
)
    
    var link7 = "https://youtu.be/ZgWnU2nagk8"
    const KLANG7 = <Klang titel={titel7} interpret={interpret7} text={text7} link={link7}/>
    
 
    var titel8 = "VON GUTEN MÄCHTEN";
    var interpret8 = "musik: siegfried fietz | text: dietrich bonhoeffer";
//    var text5 = "der lutherische theologe dietrich bonhoeffer verfasste diese verse über trost, hoffnung und gottvertrauen 1944 in gestapo-haft im angesicht seiner bevorstehenden hinrichtung."
    
        
    var text8 = (
      <div>
        <div>der lutherische theologe dietrich</div>
        <div>bonhoeffer verfasste diese verse über</div>
        <div>trost, hoffnung und gottvertrauen</div>
        <div>1944 in gestapo-haft im angesicht seiner</div>
        <div>bevorstehenden hinrichtung.</div>
      </div>
)
    
    var link8 = "https://youtu.be/wHCyV0zYKho";
    const KLANG8 = <Klang titel={titel8} interpret={interpret8} text={text8} link={link8}/>

//    #endregion 

    if(window.innerWidth > 1745 && changer === null) {
      console.log("über 1745");
      setChanger(false)
    }

    if(window.innerWidth < 1745 && changer === null) {
      console.log("unter 1745");
      setChanger(true)
    }


    if(window.innerWidth > 1745 && changer === true) {
      console.log("über 1745");
      setChanger(false)
    }
    
    if(window.innerWidth < 1745 && changer === false) {
      console.log("unter 1745");
      setChanger(true)
    }


    // console.log("weite: ",dimensions.width);
    return (

        <div>
        
        {/* { dimensions.width > 1745 ? nur 1 bei 905  */}
        {/* Ich muss eigentich neu rendern, wenn es zu groß ist */}
        { window.innerWidth > 1745 ? ( 
          <Carousel indicators={false} activeIndex={index} onSelect={handleSelect} className="w-100  klaengeContainer" defaultActiveIndex={0} pause={'hover'}>
        <Carousel.Item  interval={15000}>
          <div className="karussel_row">
            {KLANG0}
            {KLANG1}
            {KLANG2}
          </div>
        </Carousel.Item>

        <Carousel.Item interval={15000} >
        <div className="karussel_row" >
            {KLANG3}
            {KLANG4}
            {KLANG5}
          </div>
        </Carousel.Item> 
        
        <Carousel.Item interval={15000} >
        <div className="karussel_row" >
            {KLANG6}
            {KLANG7}
            {KLANG8}
          </div>
        </Carousel.Item> 

        </Carousel>) : window.innerWidth > 905 ?

        ( <Carousel indicators={false} activeIndex={index} onSelect={handleSelect} className="w-100  klaengeContainer" defaultActiveIndex={0}>
          <Carousel.Item interval={15000}>
          <div className="karussel_row" >
            {KLANG0}
            {KLANG1}
          </div>
        </Carousel.Item>

        <Carousel.Item interval={15000}>
        <div className="karussel_row" >
            {KLANG2}
            {KLANG3}
          </div>
        </Carousel.Item>
        <Carousel.Item interval={15000}>
        <div className="karussel_row" >
            {KLANG4}
            {KLANG5}
          </div>
        </Carousel.Item>
        <Carousel.Item interval={15000}>
        <div className="karussel_row" >
            {KLANG6}
            {KLANG7}
          </div>
        </Carousel.Item>
        <Carousel.Item interval={15000}>
        <div className="karussel_row" >
            {KLANG8}
          </div>
        </Carousel.Item>
         </Carousel>) : (

<Carousel indicators={false} activeIndex={index} onSelect={handleSelect} className="w-100  klaengeContainer" defaultActiveIndex={0}>
        <Carousel.Item  interval={15000}>
          <div className="karussel_row">
            {KLANG0}
          </div>
        </Carousel.Item >

        <Carousel.Item  interval={15000}>
          <div className="karussel_row">
            {KLANG1}
          </div>
        </Carousel.Item >
        <Carousel.Item interval={15000} >
          <div className="karussel_row">
            {KLANG2}
          </div>
        </Carousel.Item>
        <Carousel.Item  interval={15000}>
          <div className="karussel_row">
            {KLANG3}
          </div>
        </Carousel.Item>
        <Carousel.Item  interval={15000}>
          <div className="karussel_row">
            {KLANG4}
          </div>
        </Carousel.Item>
        <Carousel.Item  interval={15000}>
          <div className="karussel_row">
            {KLANG5}
          </div>
        </Carousel.Item>
        <Carousel.Item  interval={15000}>
          <div className="karussel_row">
            {KLANG6}
          </div>
        </Carousel.Item>
        <Carousel.Item  interval={15000}>
          <div className="karussel_row">
            {KLANG7}
          </div>
        </Carousel.Item>
        <Carousel.Item  interval={15000}>
          <div className="karussel_row">
            {KLANG8}
          </div>
        </Carousel.Item>
        </Carousel>
        )
      }
      </div>
    );
  }

 export default withContainer(Klaenge)