import React from 'react';
import './kontakt_m.css'

function Kontakt(props) {
    
    const niko = (
        <div>
            <div className="cluster_1_m">
                <div style={{color: 'black'}}>NIKOLAI</div>
                <div style={{color: 'black'}}>WOELLER</div>
                <em style={{color: 'black'}}>gesang, gitarre</em>
            </div>
            <div className="cluster_2_m">
                <a href="tel:+491792147478" style={{color: 'black'}}>+49 179 2147478</a>
                <a className="kontakt_block_m" href="mailto:nikolai@licht-musik.eu">nikolai</a>
                <a className="kontakt_block_m" href="mailto:nikolai@licht-musik.eu">@licht-musik.eu</a>
            </div>
        </div>
    )

    const tobi = (
        <div >
            <div className="cluster_1_m">
                <div style={{color: 'black'}}>TOBIAS</div>
                <div style={{color: 'black'}}>RÖSCH</div>
                <em style={{color: 'black'}}>gesang, kontrabass</em>
            </div>
            <div className="cluster_2_m">
                <a href="tel:+4915154770170" style={{color: 'black'}}>+49 151 54770170</a>
                <a className="kontakt_block_m" href="mailto:tobias@licht-musik.eu">tobias</a>    
                <a className="kontakt_block_m" href="mailto:tobias@licht-musik.eu">@licht-musik.eu</a>
            </div>
        </div>
    )

    return (

        <div className="kontakt_m_container">
            <div className="kontakt_m">
                <div className="kontakt_links_m">{niko}</div>
                <div className="kontakt_rechts_m">{tobi}</div>
            </div>
        </div>

    )
}

export default Kontakt