import React, {Component} from 'react';
import './container.css';

class LichtContainer extends Component {
    state = {}

    useMe = () => {
        
    }

    render() {
        const {component: C, ...props} = this.props
        return (
                <div className="lichtcontainer">
                <C {...props} />
            </div>
        )
    }
}

export function withContainerLicht(component) {
    return function(props) {
        return (
            <LichtContainer component={component} {...props}/>
        )
    }
}