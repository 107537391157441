const impressumReducer = (state = false, action) => {
  
    console.log("Ich bib in licht.js - reducer");
    
    console.log("impressum.js: ", JSON.stringify(action))
        switch(action.type) {
            case 'IMPRESSUM':
                 state = action.payload;
                // return action.bool;
                return state;
            default: 
                return state
        }
    
    }
    
    export default impressumReducer; 