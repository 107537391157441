const lichtReducer = (state = true, action) => {
  
    console.log("Ich bib in licht.js - reducer");
    
    console.log("licht.js: ", JSON.stringify(action))
        switch(action.type) {
            case 'LICHT':
                 state = action.payload;
                // return action.bool;
                return state;
            default: 
                return state
        }
    
    }
    
    export default lichtReducer; 