import React from 'react';
import { withContainerLicht } from '../Container/containerlicht.js'
import './licht.css'

var test1 = " ist der ursprung allen lebens. es ist frequenz, wesen und essenz aller farben und steht für geistigkeit und transzendenz. "
var test1b = "es symbolisiert freude und feierlichkeit, geistige ruhe, frieden und stille. ohne licht gibt es kein leben und kein leben existiert ohne licht.";

var test2 = (<div>
    <div className="licht_c">unsere musik ist die klangliche entsprechung des lichtes. feine ausgesuchte lieder mit texten, die uns bewegen, werden mit zweistimmigem</div>
    <div className="licht_c">gesang, gitarre und kontrabass auf das wesentliche verdichtet, so dass die inhaltliche und musikalische essenz zum strahlen kommt.</div>
</div>)


var test3 = (<div>
    <div className="licht_c"> <span style={{color: 'black'}}> LICHT </span>setzt einen kontrapunkt zur betriebsamen hektik unserer turbulenten zeit und kreiert für konzerte und vielerlei andere anlässe</div>
    <div className="licht_c"> mit musik von simon and garfunkel über joy division bis hin zu johann sebastian bach kunstvolle momente fröhlicher wie auch meditativer natur.</div>
</div>)

function Licht(props) {
    return (
        <div className="licht_container">
        <div className="licht_ab">        
                <div className="licht_a">
                    <span style={{color: 'black'}}> LICHT </span> {test1}
                </div>
                <div className="licht_b">{test1b}</div>
            </div>    
            <div className="licht ">
                {test2}
            </div>
            <div className="licht">
                {test3}
            </div>

        </div>
    )
}

export default withContainerLicht(Licht)