
// gegen eigene reducer austauschen
import impressumReducer from './impressum';
import lichtReducer from './licht'


import {combineReducers} from 'redux';


const allReducers = combineReducers({
    impressum: impressumReducer,
    licht: lichtReducer 
});

export default allReducers;