import React, {useEffect} from 'react';
import './footer.css'

import stern from "../../Bilder/stern_weiss.png";

var classHome = "footerElement footerElementButton"
var classLicht = "footerElement footerElementButton"
var classKlaenge = "footerElement footerElementButton"
var classKontakt = "footerElement footerElementButton"
var classImpressum = "footerImpressum footerElementButton";

function handleActive(selected) {
    if(selected==="Home") {
        classHome = "footerElement footerElementButtonActive";
        classLicht = "footerElement footerElementButton";
        classKlaenge = "footerElement footerElementButton";
        classKontakt = "footerElement footerElementButton";
        classImpressum = "footerImpressum footerElementButton";
    }

    if(selected==="Licht") {
        classHome = "footerElement footerElementButton";
        classLicht = "footerElement footerElementButtonActive";
        classKlaenge = "footerElement footerElementButton";
        classKontakt = "footerElement footerElementButton";
        classImpressum = "footerElementButton footerImpressum";
    }

    if(selected==="Klaenge") {
        classHome = "footerElement footerElementButton";
        classLicht = "footerElement footerElementButton";
        classKlaenge = "footerElement footerElementButtonActive";
        classKontakt = "footerElement footerElementButton";
        classImpressum = "footerImpressum footerElementButton";
    }

    if(selected==="Kontakt") {
        classHome = "footerElement footerElementButton";
        classLicht = "footerElement footerElementButton";
        classKlaenge = "footerElement footerElementButton";
        classKontakt = "footerElement footerElementButtonActive";
        classImpressum = "footerImpressum footerElementButton";
    }

    if(selected==="Impressum") {
        classHome = "footerElement footerElementButton";
        classLicht = "footerElement footerElementButton";
        classKlaenge = "footerElement footerElementButton";
        classKontakt = "footerElement footerElementButton";
        classImpressum = "footerImpressum footerElementButtonActive";
    }
}


const Footer = (props) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            props.changeStar(true)
        }, 5000);
        return () => clearTimeout(timer);
      }, []);
    return (


    
    <div className = "footer" onMouseEnter={() => (props.changeStar(true))}>

        { props.stern ? (<img className = "footerImage" src={stern} alt=""/>) :
            (<>
            <div className="footer_container">
                <p className = {classHome} onClick={() => (props.aktion(0),props.impressumAktion(true), handleActive("Home"), props.changeStar(true))}>HOME</p>
                <p className = "footerElement footerString">|</p>
                <p className = {classLicht} onClick={() => (props.aktion(1),props.impressumAktion(true), handleActive("Licht"))}>LICHT</p>
                <p className = "footerCenter footerString">|</p>
                <p className = {classKlaenge} onClick={() => (props.aktion(2),props.impressumAktion(true), handleActive("Klaenge"))}>KLÄNGE</p>
                <p className = "footerElement footerString">|</p>
                <p className = {classKontakt} onClick={() => (props.aktion(3), props.impressumAktion(true), handleActive("Kontakt"))}>KONTAKT</p>

                {/* new */}
                </div>
                <p className = {classImpressum} onClick={() => (props.impressumAktion(!props.impressumState), handleActive("Impressum"))}>impressum</p>
            </>)
        }

    </div>
)
    }
export default Footer;