import React from 'react';
import { withContainer } from '../Container/containerlicht_m.js'
import './licht_m.css'

var test1 = (<div>
    <div><span style={{color: 'white'}}> LICHT </span> ist der ursprung allen lebens. </div>
    <div>es ist frequenz, wesen und essenz aller</div>
    <div>farben und steht für geistigkeit und</div>
    <div>transzendenz. es symbolisiert freude und </div>
    <div>feierlichkeit, geistige ruhe, frieden und stille.</div>
    <div> ohne licht gibt es kein leben und kein leben</div>
    <div> existiert ohne licht.</div>
</div>)


var test2 = (
    <div>
        <div>unsere musik ist die klangliche</div>
        <div>entsprechung des lichtes. feine ausgesuchte</div>
        <div>lieder mit texten, die uns bewegen, werden</div>
        <div>mit zweistimmigem gesang, gitarre und </div>
        <div>kontrabass auf das wesentliche verdichtet,</div>
        <div>so dass die inhaltliche und musikalische</div>
        <div> essenz zum strahlen kommt.</div>
    </div>
)

var test3 = (
    <div>
        <div><span style={{color: 'white'}}> LICHT </span> setzt einen kontrapunkt zur  </div>
        <div>betriebsamen hektik unserer turbulenten</div>
        <div>zeit und kreiert für konzerte und vielerlei</div>
        <div>andere anlässe mit musik von simon and</div>
        <div>garfunkel über joy division bis hin zu</div>
        <div>johann sebastian bach kunstvolle momente</div>
        <div>fröhlicher wie auch meditativer natur.</div>
    </div>
)



function Licht(props) {
    return (
        // <div className="licht_container">
        <div className="licht_container_II_m">
            <div className="header_m header_licht_m"> LICHT </div>
            <div className="licht_space">{/* test */}</div> 
                <div className="licht_m licht_a_m">
                     {test1}
                </div>
                <div className="licht_m">
                    {test2}
                </div>
                <div className="licht_m">
                    {test3}
                </div>
        </div>

    )
}

// export default Licht
 export default withContainer(Licht)