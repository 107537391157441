import React from 'react';
import { withContainer } from '../Container/container.js'
import Logo from '../../Bilder/lichtschriftlogoschwarz.png';
import './home.css';


function Home(props) {
    return (
        <div>
            <img className="logo" src={Logo} alt="Lichtlogo" />
            <h5 className="maintext" style={{color: 'black'}}>
                nikolai woeller <em className="seperator_left"> gesang, gitarre </em> <span className="seperator">|</span> tobias rösch <em className="seperator_left"> gesang, kontrabass </em>
            </h5>
            <div className="subtext">
               <p className="dot">•</p> klänge am rande der stille <p className="dot">•</p>
            </div>
        </div>
    )
}

export default withContainer(Home)