import React, {useState} from 'react'; 
import Menu_m from './menu_m.js';

import Home_m from './home_m';
import Homesub_m from './homesub_m';
import Kontakt_m from './kontakt_m';
import Licht_m from './licht_m';
import Klänge_m from './klänge_m';

// toDo
import Impressum_m from './impressum_m'
import Backgroundimg_m from './mobile_background/backgroundimg_m'

import { withContainer } from '../Container/container_m.js'

import './mobile.css'

const Mobile = (props) => {

    const [item, setItem] = useState(0)
    
    const changeContent = (item) => {
        setItem(item);
    }



    return (    
        <div className="App_m">
            <Menu_m aktion={changeContent}/>
          <div className="App-header_m">
          <div >
                <div className="size_m">
                {
                item === 0  ? 
                (

                <div className="fixedcontainer_m">
                    <Backgroundimg_m text={null}/>
                    <Home_m />  {/* Hier ist der Text direkt im Home */}
                </div>
                
                )  
                : item === 1 ? <Licht_m />
                : item === 2 ? <Klänge_m />
                : item === 3 ? (
                    <div className="fixedcontainer_m">

                        {/* Hier habe ich den Text im Hintergrund */}
                        <Backgroundimg_m kontakt={true} text={"KONTAKT"}/>
                        {/* der eigentliche TExt steht ja im kontakt */}
                        <Kontakt_m />
                    </div>
                )
                : <Impressum_m/> }
            </div>
        </div>
        </div>

        </div>
    )
}

// export default withContainer(Mobile); 
export default Mobile; 