import React, {useState} from 'react';
import './impressum_m.css';


const Impressum = (props) => {

                return (
                    <div>
                        <div className="overlay_d">   
                            <div className="overlay-content_d impressumStyles_m">
                                <div>IMPRESSUM</div>
                                <br></br>
                                <div><em>verantwortlich für alle inhalte</em></div>
                                <div><em>dieser internetseite:</em></div>
                                <br></br>
                                <div>nikolai woeller</div>
                                <div>erikastraße 99</div>
                                <div>20251 hamburg</div>
                                <br></br>
                                <div>+49 179 2147478</div>
                                <div>nikolai@licht-musik.eu</div>
                                <br></br>
                                <br></br>
                                <em>haftung für links: </em>
                                <br></br>
                                <br></br>
                                <div>unser angebot enthält links zu externen</div>
                                <div>webseiten dritter, auf deren inhalte</div>
                                <div>wir keinen einfluss haben. deshalb können </div>
                                <div> wir für diese fremden inhalte auch keine</div>
                                <div>gewähr übernehmen. für die inhalte</div>
                                <div>der verlinkten seiten ist stets der jeweilige</div>
                                <div>anbieter oder betreiber der seiten</div>
                                <div>verantwortlich. die verlinkten seiten</div>
                                <div>wurden zum zeitpunkt der verlinkung</div> 
                                <div>auf mögliche  rechtsverstöße überprüft.</div>
                                <div>rechtswidrige inhalte waren zum</div>
                                <div>zeitpunkt der verlinkung nicht erkennbar.</div>
                                <div>eine permanente inhaltliche kontrolle </div>
                                <div>der verlinkten seiten ist jedoch ohne </div>
                                <div>konkrete anhaltspunkte einer rechts-</div> 
                                <div>verletzung nicht zumutbar. bei bekannt- </div>
                                <div>werden von rechtsverletzungen werden</div>
                                <div> wir derartige links umgehend entfernen.</div>
                                <br></br>
                                <br></br>
                                <div><em>gestaltung:</em> nikolai woeller</div>
                                <div>nikolai@licht-musik.eu</div>
                                <br></br>
                                <div><em>programmierung:</em> alexander linß</div>
                                <div>alexanderlinss@web.de</div>
                                <br></br>
                                <div><em>fotografie:</em> vera linß</div>
                                <div>info@vera-linss.de</div>
                                <br></br>
                                <div className="ende">&#9400; 2021 LICHT</div>
                            </div>
                        </div>
                    </div>
                )
    }

export default Impressum; 