import React from 'react';
import { withContainer } from '../../Container/container_m';
import './backgroundimg_m.css';

function backgroundimg(props){

    return(
        <div>
        { 
            props.text !== null ? 
        (        
        <div>
            <div className="header_m header_kontakt_m"> {props.text} </div>

            {/* <div className="background_container"> */}
            <div className="background_container_kontakt">
                <div className="backgroundimg_kontakt_m"></div>
            </div>
        </div>
        )  : (
            <div>
                <div className="background_container">
                    <div className="backgroundimg_m"></div>
                </div>
            </div>
            )
        }
        </div>
    )
}

export default withContainer(backgroundimg)