// impressum 
export const impressum = (bool) => {
    console.log("Das ist mein Bool-impressum: ", bool)
    return ({type:"IMPRESSUM", payload: bool})
}


// licht menu
export const licht = (bool) => {
    console.log("Das ist mein Bool-licht: ", bool)
    return ({type:"LICHT", payload: bool})
}
