import React from 'react';
import './home_m.css';
import Logo from '../../Bilder/lichtschriftlogo.png';


function Home(props) {
    return (
        <div className="homesub_m">        
             <img className="logo_m" src={Logo} alt="Lichtlogo" />
            <h5 className="maintext_m" style={{color: 'black'}}>
                <div className="no_border_m">
                    nikolai woeller 
                    <br></br>
                    <em className="seperator_left_m"> gesang, gitarre </em> 
                </div>
                {/* <div> <span className="seperator_m">|</span> </div> */}
                <div className="border_m">
                    tobias rösch 
                    <br></br>
                    <em className="seperator_left_m"> gesang, kontrabass </em>
                </div>
            </h5>

            <div className="subtext_m"> 
               <p className="dot_m">•</p> klänge am rande der stille <p className="dot_m">•</p>
            </div>
        </div>
    )
}

export default Home


 