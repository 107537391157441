import React, {useState, useEffect} from 'react';
import './menu_m.css';
import stern from "../../Bilder/stern.png";
import sternwhite from "../../Bilder/stern_weiss.png";
import balken from "../../Bilder/menüzeichen_balken.png";

const Menu = (props) => {
    const [star, setStar] = useState(null)
    const [menulogo, setMenulogo] = useState(sternwhite)

    useEffect(() => {setInterval(() => {setMenulogo(balken)}, 3000)},[])

    const changeNav = () => {

        if(star === true) {
            document.getElementById("myNav").style.height = "0%";
          } else {
            document.getElementById("myNav").style.height = "100%";
          }
          setStar(!star);
    }


    return (
        <div>
            {
                star == null || star == false ?            
                (
                    <header className={"header"}>
                        <img className="footerImage_m" onClick={() => changeNav()} src={menulogo} alt=""/>
                    </header>
                ) : (
                    <header className={"header_white"}>
                        <img className="footerImage_m" onClick={() => changeNav()} src={sternwhite} alt=""/>
                    </header>
                )
            }

            <div id="myNav" class="overlay">
                <div class="overlay-content">
                <a onClick={() => (props.aktion(0), changeNav())} className="menu-item menu-item-home">HOME</a>
                <a onClick={() => (props.aktion(1), changeNav())} className="menu-item menu-item-licht">LICHT</a>
                <a onClick={() => (props.aktion(2), changeNav())} className="menu-item menu-item-klaenge">KLÄNGE</a>
                <a onClick={() => (props.aktion(3), changeNav())} className="menu-item">KONTAKT</a>
                {/* impressum */}
                <a onClick={() => (props.aktion(4), changeNav()) } className="menu-item-bottom">impressum</a>
                </div>
            </div>

        </div>
    )
}

export default Menu; 