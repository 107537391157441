import React from 'react'
import './Klang.css'


export default function Klang(props) {

    var titel = props.titel;
    var interpret = props.interpret;
    var text = props.text;
    var link = props.link;

    return (
        <div className="klang">
            <div className="klang_title" style={{color: 'black'}}> {titel} </div>
            <em className="klang_interpret">{interpret}</em>

            <div className="klang_text">{text}</div>
            
            <div style={{color: 'black'}}>
            <a href={link} target="_blank" className="klang_btn btn-accent">LIED HÖREN </a>
            </div>
        </div>
    )
}
