import React, {useState} from 'react'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Custom.css'; // eigene Pfeile
import {isMobileOnly} from 'react-device-detect';

import Home from './Components/Elements/home.js';
import Licht from './Components/Elements/licht.js';
import Klänge from './Components/Elements/Klänge/klänge.js';
import Kontakt from './Components/Elements/kontakt.js';

import Footer from './Components/Footer/footer';
import Impressum from './Components/Elements/impressum';

import Mobile from './Components/Mobile_elements/mobile.js';


// redux
import {useSelector, useDispatch } from 'react-redux';
import {impressum, licht } from './actions';




function App() {

// redux
const impressumState = useSelector(state => state.impressum)
const lichtState = useSelector(state => state.licht)
const dispatch = useDispatch();  

const changeImpressum = (bool) => {
  dispatch(impressum(bool))
}

const changeLicht = (bool) => {
  dispatch(licht(bool))
}


const [item, setItem] = useState(0);
const [star, setStar] = useState(true);
const [impressum, setImpressum] = useState(false);

const changeFooter = (item) => {
    setItem(item);
}

const showImpressum = (bool) => {
  console.log("changed Impressum: " + bool);
  setImpressum(bool);
}

const changeStar = (param) => { //test!
  if(param === false) {
    setStar(true);
  } else if(star === true) {
    setStar(false);
  }
}



if(isMobileOnly) {
  return(
    <div className="mobile-body">
      <Mobile />
    </div>
  )

} else {
  return (
    <div className="App">
      <header className="App-header">
      <Impressum impressumAktion={changeLicht} impressumState={lichtState}/>
        <div >
        <div className="backgroundimg">
          {lichtState === true ? 
          <div className="gross">
            {item === 0  ?  <Home />: item === 1  ?  <Licht />: item === 2 ? <Klänge /> : <Kontakt />}
          </div>
          : <div className="gross"></div>} 
        </div>
        </div>
      </header>
      <Footer  aktion={changeFooter} impressumAktion={changeLicht} impressumState={lichtState} stern={star} changeStar={changeStar}/>
    </div>
  );
}
}

export default App;
