import React from 'react';
import { withContainer } from '../Container/container_m.js'
import Logo from '../../Bilder/lichtschriftlogo.png';
import './homesub_m.css';


function Homesub(props) {

    
    return (
        <div className="homesub_m">
             <img className="logo_m" src={Logo} alt="Lichtlogo" /> 
            <h5 style={{color: 'black'}}>
                nikolai woeller <em> gesang, gitarre </em> <span >|</span> tobias rösch <em > gesang, kontrabass </em>
            </h5>

            <div >
               <p className="dot">•</p> klänge am rande der stille <p className="dot">•</p>
            </div>
        </div>
    )
}

export default withContainer(Homesub)