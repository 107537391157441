import React from 'react';
import { withContainer } from '../Container/container.js'
import './kontakt.css'

function Kontakt(props) {
    
    const niko = (
        <div>
            <div>
                <div className="cluster_1">
                    <div>NIKOLAI WOELLER</div>
                    <em style={{color: 'black'}}>gesang, gitarre</em>
                </div>
                <div className="cluster_2">
                    <div style={{color: 'black'}}>+49 179 2147478</div>
                    <a className="kontakt_block" href="mailto:nikolai@licht-musik.eu">nikolai@licht-musik.eu</a>
                </div>
            </div>
        </div>
    )


    const tobi = (
        <div>
            <div >
                <div className="cluster_1">
                    <div>TOBIAS RÖSCH</div>
                    <em style={{color: 'black'}}>gesang, kontrabass</em>
                </div>
                <div className="cluster_2">
                    <div style={{color: 'black'}}>+49 151 54770170</div>
                    <a className="kontakt_block" href="mailto:tobias@licht-musik.eu">tobias@licht-musik.eu</a>
                </div>
            </div>
        </div>
    )


    // const tobi = (
    //     <div >
    //         <div>TOBIAS RÖSCH</div>
    //         <br></br>
    //         <em style={{color: 'black'}}>gesang, kontrabass</em>
    //         <br></br>
    //         <br></br>
    //         <div style={{color: 'black'}}>+49 151 54770170</div>
    //         <br></br>
    //         <div>tobias@licht-musik.eu</div>
    //     </div>
    // )

    return (
        <div className="kontakt">
            <div className="kontakt_links">{niko}</div>
            <div className="kontakt_rechts">{tobi}</div>
        </div>
    )
}

export default withContainer(Kontakt)